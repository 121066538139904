<template>
  <pricing-import
    class="flyer-pricing-import"
    formName="flyer-pricing-import"
    moduleSlug="flyer-pricing-import"
    controllerName="FlyerPricingImport.vue"
    moduleName="Flyer Pricing Import"
    priceTypeId="1"
  ></pricing-import>
</template>

<script>
import PricingImport from "./PricingImport.vue";
export default {
  name: "FlyerPricingImport",
  metaInfo() {
    return {
      title: `${this.$t("general.flyer-pricing")} | ${this.$t(
        "general.pricing-manager"
      )}`,
    };
  },
  components: {
    PricingImport,
  },
};
</script>
